
import {
  computed,
  createVNode,
  defineComponent,
  reactive,
  ref,
  toRefs,
  onMounted,
} from "vue";
import { CalendarOutlined } from "@ant-design/icons-vue";
import AppealTable from "../components/AppealTable.vue";
import UploadDrawer from "../components/UploadDrawer.vue";
import AccepteOrNotModal from "../components/AcceptOrNotModal.vue";
import useAppeal from "@/hooks/communication/useAppeal";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import { useRouter } from "vue-router";
import { communicationData } from "@/API/communication/appeal";
import {
  communicationApprove,
  communicationReject,
} from "@/API/communication/appeal";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/claim/downloadFile";
import moment from "moment";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    CalendarOutlined,
    AppealTable,
    AccepteOrNotModal,
    UploadDrawer,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      if (from.fullPath === "/communication/appeal/detail") {
        const mainSearchCondition = window.sessionStorage.getItem(
          "mainSearchCondition"
        );
        if (mainSearchCondition !== null) {
          vm.activeKey = window.sessionStorage.getItem("activeKey");
          const searchCondition = JSON.parse(mainSearchCondition);
          vm.selectedDate = searchCondition.confirmDeadline;
          vm.vinNos = searchCondition.vins;
          if (searchCondition.vins.length === 0) {
            vm.vinNo = searchCondition.vin;
          } else {
            vm.vinNo = searchCondition.vins[0];
          }
          vm.model = searchCondition.modelName;
          vm.modelYear = searchCondition.modelYear;
          vm.submissionDate = [
            searchCondition.claimDateStart === ""
              ? ""
              : moment(searchCondition.claimDateStart),
            searchCondition.claimDateEnd === ""
              ? ""
              : moment(searchCondition.claimDateEnd),
          ];
          vm.programCode = searchCondition.programCode;
          vm.programNameCn = searchCondition.programNameCn;
          vm.checkResult = searchCondition.checkResult;
          vm.communicationStatus = searchCondition.communicationStatus;
          vm.appealStatus = searchCondition.appealStatus;
          vm.queryDealerTrade = searchCondition.queryDealerTrade;
        }
      }
    });
  },
  setup() {
    const router = useRouter();
    const {
      tableData,
      spaceSize,
      batchPlaceholder,
      textContentNo,
      input,
      clearBatchQuery,
      batchQuery,
      batchQueryEvent,
      textNoArray,
      closeBatchQueryModal,
      textChangEvent,
      vinNo,
      vinNos,
      model,
      modelYear,
      submissionDate,
      programCode,
      programName,
      checkResult,
      checkResultList,
      communicationStatus,
      communicationStatusList,
      appealStatus,
      queryDealerTrade,
      appealStatusList,
      modelYearList,
      reset,
      confirmDateList,
      exemptionDateList,
      selectedDate,
      init,
      dealerCode,
      buList,
      generateDeadlinesStr,
    } = useAppeal();

    const tableDataCount = reactive({
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
    });
    const activeKey = ref<string>("toBeConfirmedData");

    //? 页面初始化相关
    const getCommunicationData = () => {
      const len = vinNos.value.length;
      const params = {
        confirmDeadline: generateDeadlinesStr(),
        bus: buList.value.join(","),
        dealerCode: dealerCode.value,
        vin: len === 0 ? vinNo.value : "",
        vins: len !== 0 ? vinNos.value : "",
        modelName: model.value,
        modelYear: modelYear.value, // 年款
        claimDateStart:
          (submissionDate.value[0] &&
            submissionDate.value[0].format("yyyy-MM-DD")) ||
          "",
        claimDateEnd:
          (submissionDate.value[1] &&
            submissionDate.value[1].format("yyyy-MM-DD")) ||
          "",
        programCode: programCode.value,
        programNameCn: programName.value,

        checkResult: checkResult.value, // 检查结果
        communicationStatus: communicationStatus.value, // 沟通状态
        appealStatus: appealStatus.value, // 申诉状态
        queryDealerTrade: queryDealerTrade.value,
      };
      window.sessionStorage.setItem(
        "mainSearchCondition",
        JSON.stringify(params)
      );
      communicationData(params).then((res: any) => {
        tableData.toBeConfirmedData = res.to_be_confirmed;
        tableData.submitAppealDoc = res.pending_appeal.map((item: any) => {
          if (!item.appealStatusText && item.checkResultCnText === '待定' && item.communicationTimes >= 1 && item.offerTypeNameEn === 'Demo Car') {
            item.appealStatusText = '待申诉'
          }
          return item
        });
        tableData.finished = res.completed;
        tableData.toBeConfirmedDeductionData = res.to_be_paid || [];
        tableDataCount.count1 = tableData.toBeConfirmedData.length;
        tableDataCount.count2 = tableData.submitAppealDoc.length;
        tableDataCount.count3 = tableData.finished.length;
        tableDataCount.count4 = tableData.toBeConfirmedDeductionData.length;
      });
    };

    const clickConfirmDate = (index: number) => {
      confirmDateList.value[index].selected =
        !confirmDateList.value[index].selected;
      getCommunicationData();
    };
    const clickExeptionDate = (index: number) => {
      exemptionDateList.value[index].selected =
        !exemptionDateList.value[index].selected;
      getCommunicationData();
    };

    const showDate = (date: any) => {
      if (date.type === "confirm") return true;
      if (
        date.type === "exemption" &&
        activeKey.value === "toBeConfirmedDeductionData"
      )
        return true;
      return false;
    };

    //? 点击接受或者不接受
    const appealRef = reactive({
      toBeConfirmedData: null,
      toBeConfirmedDeductionData: null,
      submitAppealDoc: null,
      finished: null,
    });
    const modalVisible = ref<boolean>(false);
    const acceptType = ref<string>("");
    const selectedClaimsStatistics = ref([
      { checkingResult: "通过", claimCount: 0 },
      { checkingResult: "未通过", claimCount: 0 },
      { checkingResult: "待定", claimCount: 0 },
    ]);
    const selectedClaimIds = ref<string[]>([]);
    //* 校验逻辑
    const acceptCheck = (selectedData: any[], type: string): boolean => {
      const WARNING_TEXT = {
        accept: "‘接受’的申请无需再次’接受‘",
        notAccept: "‘不接受’的申请无需再次’不接受‘",
      };
      const COM_TYPE = {
        accept: "1",
        notAccept: "2",
      };
      const index = selectedData.findIndex((row) => {
        return row.communicationStatus === COM_TYPE[type];
      });
      if (index !== -1) {
        message.error(WARNING_TEXT[type]);
        return false;
      }
      return true;
    };
    //* 数据统计
    const dataStat = (selectedData: any[]) => {
      let pass = 0;
      let notPass = 0;
      let undetermined = 0;
      selectedData.forEach((data: any) => {
        switch (data.checkResultCnText) {
          case "通过":
            pass += 1;
            break;
          case "未通过":
            notPass += 1;
            break;
          case "待定":
            undetermined += 1;
            break;
        }
      });
      const selectedClaimsStatisticsValue = selectedClaimsStatistics.value;
      selectedClaimsStatisticsValue[0].claimCount = pass;
      selectedClaimsStatisticsValue[1].claimCount = notPass;
      selectedClaimsStatisticsValue[2].claimCount = undetermined;
    };

    //* 被选数据的ids
    const genSelectedIds = (selectedData: any[]) => {
      selectedClaimIds.value = selectedData.map((data) => {
        return data.id;
        // return 'abcdefg';
      });
    };

    const acceptClick = (type: string) => {
      const selectedData =
        appealRef[activeKey.value].selectedRows.length === 0
          ? tableData[activeKey.value]
          : appealRef[activeKey.value].selectedRows;
      if (acceptCheck(selectedData, type)) {
        dataStat(selectedData);
        genSelectedIds(selectedData);
        acceptType.value = type;
        modalVisible.value = true;
      }
    };

    const closeModal = () => {
      modalVisible.value = false;
    };

    const confirm = () => {
      const TAB_TYPE = {
        toBeConfirmedData: "to_be_confirmed",
        submitAppealDoc: "pending_appeal",
        toBeConfirmedDeductionData: "to_be_paid",
        finished: "completed",
      };
      const params = {
        bus: buList.value.join(","),
        dealerCode: dealerCode.value,
        tabType: TAB_TYPE[activeKey.value],
        ids: selectedClaimIds.value,
      };
      const communication = {
        accept: communicationApprove,
        notAccept: communicationReject,
      };
      communication[acceptType.value](params).then((res: any) => {
        if (res.code === "50276") {
          Modal.confirm({
            title: "提示",
            icon: createVNode(ExclamationCircleOutlined),
            content: "首次申报 demo car 项目，请先点击“上传文档”提交申请材料！",
            okText: "确认",
            cancelButtonProps: { style: { display: "none" } } as any,
          });
        } else {
          getCommunicationData();
        }
        modalVisible.value = false;
      });
    };

    const goToHistory = () => {
      router.push({
        name: "appealHistory",
      });
    };

    const exportResult = () => {
      const params = {
        url: "/claimapi/dealer/claim/detail/export",
        method: "post",
        params: {
          appealStatus: appealStatus.value, // 申诉状态
          queryDealerTrade: queryDealerTrade.value,
          bus: buList.value.join(","),
          checkResult: checkResult.value, // 检查结果
          claimDateStart:
            (submissionDate.value[0] &&
              submissionDate.value[0].format("yyyy-MM-DD")) ||
            "",
          claimDateEnd:
            (submissionDate.value[1] &&
              submissionDate.value[1].format("yyyy-MM-DD")) ||
            "",
          communicationStatus: communicationStatus.value, // 沟通状态
          confirmDeadline: generateDeadlinesStr(),
          dealerCode: dealerCode.value,
          modelName: model.value,
          modelYear: modelYear.value, // 年款
          programCode: programCode.value,
          programNameCn: programName.value,
          vin: vinNos.value.join(","),
        },
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    const uploadDrawerVisible = ref<boolean>(false);
    const selectedClaim = ref<any>({});
    const drawerType = ref<string>("");
    const openUploadDrawer = (record: any, type: string) => {
      uploadDrawerVisible.value = true;
      selectedClaim.value = record;
      drawerType.value = type;
    };
    const closeUploadDrawer = () => {
      uploadDrawerVisible.value = false;
    };
    const refresh = () => {
      getCommunicationData();
    };
    onBeforeRouteLeave((to) => {
      if (to.fullPath !== "/communication/appeal/detail") {
        window.sessionStorage.removeItem("mainSearchCondition");
        window.sessionStorage.removeItem("activeKey");
      } else {
        window.sessionStorage.setItem("activeKey", activeKey.value);
      }
    });

    onMounted(() => {
      init(getCommunicationData);
    });

    return {
      selectedDate,
      clickConfirmDate,
      clickExeptionDate,
      reset,
      activeKey,
      ...toRefs(tableDataCount),
      vinNo,
      vinNos,
      model,
      modelYear,
      submissionDate,
      programCode,
      programName,
      checkResult,
      checkResultList,
      communicationStatus,
      communicationStatusList,
      appealStatus,
      queryDealerTrade,
      appealStatusList,
      modelYearList,
      spaceSize,
      tableData,
      getCommunicationData,
      ...toRefs(appealRef),
      modalVisible,
      acceptType,
      selectedClaimsStatistics,
      acceptClick,
      closeModal,
      confirm,
      //! ----车辆号批量查询
      batchPlaceholder,
      textContentNo,
      input,
      clearBatchQuery,
      ...toRefs(batchQuery),
      batchQueryEvent,
      textNoArray,
      closeBatchQueryModal,
      textChangEvent,
      //! 车辆号批量查询 ----
      goToHistory,
      exportResult,
      //! 上传、申诉
      uploadDrawerVisible,
      openUploadDrawer,
      closeUploadDrawer,
      refresh,
      selectedClaim,
      selectedClaimIds,
      drawerType,
      showDate,
      confirmDateList,
      exemptionDateList,
    };
  },
});
